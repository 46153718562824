<template>
  <div v-if="!loading">
    <div class="text-center mt-8">
      <v-btn
        color="primary"
        rounded
        @click="add_media_dialog()"
        fab
        top
        right
        absolute
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
    </div>
    <div>
      <SelectBriefingLanguageComponent
        @handleChangeLanguage="(e) => (selectedLanguage = e)"
      />
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th>{{ $t("title") }}</th>
          <th>{{ $t("media_type") }}</th>
          <th class="text-center">{{ $t("edit") }}</th>
          <th class="text-center">{{ $t("remove") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, index) in media.filter(
            (x) => gs.getLanguage(x.Language) === selectedLanguage
          )"
          :key="index"
        >
          <td>{{ item.Title }}</td>
          <td>{{ media_options[item.Type] }}</td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              @click="edit_media_dialog(item)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1"
              @click="open_delete_dialog(item)"
              color="red"
              min-width="0"
              small
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-if="dialog" v-model="dialog">
      <v-card>
        <v-card-title>
          <h4 class="dxa_modal_title h4">{{ $t("manage_media") }}</h4>
        </v-card-title>
        <v-card-text>
          <v-form @submit.stop.prevent="add_media()" ref="form" class="mt-5">
            <div class="mx-2">
              <v-select
                :items="index_options(media_obj.Type)"
                :label="$t('index')"
                v-model="media_obj.Index"
                outlined
              ></v-select>
              <v-text-field
                v-model="media_obj.Title"
                @keyup="isFieldsFilled"
                :rules="[required]"
                :label="$t('title')"
              />

              <v-text-field
                v-model="media_obj.VideoId"
                :rules="[required]"
                @keyup="isFieldsFilled"
                :label="$t('video_id')"
                :hint="$tc('vimeo_hint', 1)"
              />

              <div class="mt-2">
                <span>{{ $t("media_type") }}</span>
                <v-radio-group
                  :rules="[required]"
                  center
                  v-model="media_obj.Type"
                  row
                  :disabled="edit"
                >
                  <!-- <v-radio :label="$t('main')" :value="1"></v-radio> -->
                  <v-radio :label="$t('leadership_chat')" :value="2"></v-radio>
                  <v-radio :label="$t('other')" :value="0"></v-radio>
                </v-radio-group>
              </div>

              <div class="mt-2">
                <span>{{ $t("media_plataform") }}</span>
                <v-radio-group
                  @change="isFieldsFilled"
                  :rules="[required]"
                  center
                  v-model="media_obj.Plataform"
                  row
                >
                  <v-radio
                    :label="$t('vimeo')"
                    :value="videoPlatformEnum.VIMEO"
                  ></v-radio>
                  <v-radio
                    :label="$t('youtube')"
                    :value="videoPlatformEnum.YOUTUBE"
                  ></v-radio>
                </v-radio-group>
              </div>
            </div>
            <v-card-actions>
              <v-btn
                class="dxa_modal_btnSuccess"
                color="primary"
                :disabled="disabled"
                min-width="100"
                :loading="dialog_loading"
                type="submit"
                >{{ $t("save") }}</v-btn
              >
              <v-btn
                class="dxa_modal_btnError"
                color="red"
                min-width="100"
                @click="dialog = false"
                >{{ $t("cancel") }}</v-btn
              >
            </v-card-actions>
          </v-form>
          <v-alert class="ma-2" v-if="error" type="error">
            {{ $t(error) }}
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Title"
        @close="delete_dialog = false"
        @delete="remove_media"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import { videoPlatformEnum } from "@/shared/enums/VideoPlatformEnum";
import SelectBriefingLanguageComponent from "../components/SelectBriefingLanguageComponent";

export default {
  name: "ManageCompanyMedia",

  components: {
    DeleteConfirmationModal,
    SelectBriefingLanguageComponent,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    videoPlatformEnum,
    loading: false,
    dialog_loading: false,
    dialog: false,
    delete_dialog: false,
    delete_obj: null,
    company_id: null,
    error: null,
    edit: false,
    media: [],
    media_obj: {
      Title: null,
      Type: 0,
      VideoId: null,
      Plataform: 0,
    },
    disabled: true,
    selectedLanguage: null,
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    media_options() {
      return [this.$t("other"), this.$t("main"), this.$t("leadership_chat")];
    },
  },
  props: {
    BriefingId: Number,
    company_prop: Object,
  },

  async created() {
    await this.get_company_briefing_media();
  },

  methods: {
    async get_company_briefing_media() {
      this.loading = true;
      await this.apiService
        .getRequest(`companymedia/list/${this.BriefingId}`)
        .then((resp) => {
          this.media = JSON.parse(resp.message);
        });
      this.loading = false;
    },
    isFieldsFilled() {
      if (
        (this.media_obj.Title && this.media_obj.VideoId) != null &&
        (this.media_obj.Title && this.media_obj.VideoId) != "" &&
        this.media_obj.Plataform !== 0
      ) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    index_options(type) {
      var i;
      var return_list = [];
      for (
        i = 1;
        i <=
        this.media.filter(
          (x) =>
            x.Type == type &&
            this.gs.getLanguage(x.Language) === this.selectedLanguage
        ).length;
        i++
      ) {
        return_list.push(i);
      }
      if (!this.edit) {
        return_list.push(
          this.media.filter(
            (x) =>
              x.Type == type &&
              this.gs.getLanguage(x.Language) === this.selectedLanguage
          ).length + 1
        );
      }
      return return_list;
    },
    open_delete_dialog: function (media) {
      this.delete_obj = media;
      this.delete_dialog = true;
    },
    remove_media: async function (obj) {
      this.dialog_loading = true;
      this.error = null;
      await this.apiService
        .deleteRequest("companymedia/delete/".concat(obj.Id))
        .then((result) => {
          this.media = JSON.parse(result.message);
          this.delete_dialog = false;
        })
        .catch((error) => {
          this.$emit("error", error.body.message);
          this.error = error.body.message;
        });
      this.dialog_loading = false;
    },
    add_media_dialog: function () {
      let langId = 0;
      if (this.selectedLanguage === "pt") {
        langId = 0;
      } else if (this.selectedLanguage === "en") {
        langId = 1;
      } else {
        langId = 2;
      }

      this.edit = false;
      this.media_obj = {
        BriefingId: this.BriefingId,
        Type: 0,
        Title: null,
        VideoId: null,
        Plataform: 0,
        Language: langId,
        CompanyId: this.company_prop.CompanyId,
        Index: this.media.length + 1,
      };
      this.dialog = true;
    },
    edit_media_dialog: function (obj) {
      this.edit = true;
      this.media_obj = {
        Id: obj.Id,
        CompanyId: obj.CompanyId,
        BriefingId: obj.BriefingId,
        Type: obj.Type,
        Title: obj.Title,
        VideoId: obj.VideoId,
        Language: obj.Language,
        Plataform: obj.Plataform,
        Index: obj.Index,
      };
      this.dialog = true;
      this.isFieldsFilled();
    },
    checkForm: function () {
      this.error = null;
      var result = this.$refs.form.validate();
      if (result) {
        this.add_media();
      } else {
        this.dialog_loading = false;
      }
    },
    add_media: async function () {
      if (this.dialog_loading) {
        return;
      }
      this.dialog_loading = true;
      if (this.edit) {
        await this.apiService
          .putRequest("companymedia/edit", this.media_obj)
          .then((result) => {
            this.media = JSON.parse(result.message);
            this.dialog = false;
          })
          .catch((error) => {
            this.error = error.body.message;
          });
        this.dialog_loading = false;
      } else {
        await this.apiService
          .postRequest("companymedia/add", this.media_obj)
          .then((result) => {
            this.media = JSON.parse(result.message);
            this.dialog = false;
          })
          .catch((error) => {
            this.error = error.body.message;
          });
        this.dialog_loading = false;
      }
    },
  },
};
</script>
